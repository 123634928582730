import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { Flex, Td, Text, Table, Tbody, Th, Thead, Tr, Button } from "@chakra-ui/react";

import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";

import { dateConvert } from "helper/dateConvert";

function UserComponents({ data }) {
    const router = useHistory();

    function open(id) {
        router.push(`/dashboard/check-card/${id}`);
    }

    return (
        <Flex direction='column' w='100%' gap='10px'>
            <Button w={{ sm: "100%", md: "max-content" }} onClick={() => router.goBack()}>
                Назад
            </Button>

            <Card overflowX='scroll'>
                <CardBody>
                    <Table variant='striped'>
                        <Thead flex='column' justifyContent='space-between'>
                            <Tr color='gray.400'>
                                <Th color='gray.400'>id чека</Th>
                                <Th color='gray.400'>Дата и время загрузки</Th>
                                <Th color='gray.400'>Контрольная переменная</Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {data.map((el) => (
                                <Tr key={el.id}>
                                    <Td>
                                        <Flex align='center' role='button' w='max-content'>
                                            <Text
                                                fontSize='md'
                                                fontWeight='bold'
                                                onClick={() => open(el.id)}
                                            >
                                                {el.id || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Text color='gray.400' fontSize='md'>
                                                {dateConvert(el.create_date) || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Text color='gray.400' fontSize='md'>
                                                {"-"}
                                            </Text>
                                        </Flex>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </CardBody>
            </Card>
        </Flex>
    );
}

export default UserComponents;
