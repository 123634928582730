import { useState } from "react";
import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import useRequestArray from "hooks/useRequestArray";

export default function ItemTypeMenuComponent({ handleSelectItemType, index }) {
    const { responseData } = useRequestArray("item-type", "get");
    const [itemType, setItemType] = useState("");

    const handleSelect = (type, id) => {
        setItemType(type);
        handleSelectItemType(index, id);
    };

    return (
        <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                {itemType || "Выберите тип товара"}
            </MenuButton>
            <MenuList>
                {responseData.map(({ item_type_name, id }, index) => (
                    <MenuItem key={index} onClick={() => handleSelect(item_type_name, id)}>
                        {item_type_name}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
}
