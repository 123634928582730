import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Text,
} from "@chakra-ui/react";

export default function ConfirmModal({ onClose, isOpen, deleteCheckItem, id }) {
    async function confirmAndCloseModal() {
        await deleteCheckItem(id);
        onClose();
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Предупреждение!</ModalHeader>
                    <ModalBody>
                        <Text>
                            В случае удаления категории, все связанные с ней товары будут удалены!
                        </Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='red' mr={3} onClick={confirmAndCloseModal}>
                            Подтвердить
                        </Button>
                        <Button onClick={onClose}>Отмена</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
