import { SimpleGrid } from "@chakra-ui/react";
import { WalletIcon, GlobeIcon, DocumentIcon, CartIcon } from "components/Icons/Icons";

import StatComponent from "./StatComponent";
import SkeletonComponent from "./SkeletonComponent";

import useRequestObject from "hooks/useRequestObject";

export default function StatBlock() {
    const { responseData, isLoading } = useRequestObject("statistics/check-count-status", "get");

    return (
        <SimpleGrid columns={{ sm: "1", md: "3" }} spacing='24px' mb='24px'>
            {responseData && !isLoading && (
                <StatComponent
                    title={"Общее количество чеков в промоакции"}
                    amount={responseData.total}
                    icon={<WalletIcon h={"24px"} w={"24px"} />}
                />
            )}
            {isLoading && <SkeletonComponent height={15} />}

            {responseData && !isLoading && (
                <StatComponent
                    title={"Количество одобренных чеков"}
                    amount={responseData.verified}
                    icon={<GlobeIcon h={"24px"} w={"24px"} />}
                />
            )}
            {isLoading && <SkeletonComponent height={15} />}

            {responseData && !isLoading && (
                <StatComponent
                    title={"Количество отклоненных чеков"}
                    amount={responseData.notVerified}
                    icon={<CartIcon h={"24px"} w={"24px"} />}
                />
            )}
            {isLoading && <SkeletonComponent height={15} />}

            {responseData && !isLoading && (
                <StatComponent
                    title={"Количество чеков на проверке"}
                    amount={responseData.wait}
                    icon={<DocumentIcon h={"24px"} w={"24px"} />}
                />
            )}
            {isLoading && <SkeletonComponent height={15} />}

            {responseData && !isLoading && (
                <StatComponent
                    title={"Количество чеков на ожидании"}
                    amount={responseData.onHold}
                    icon={<CartIcon h={"24px"} w={"24px"} />}
                />
            )}
            {isLoading && <SkeletonComponent height={15} />}
        </SimpleGrid>
    );
}
