import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { Flex, Text, Button } from "@chakra-ui/react";

import UserComponents from "./UserComponent";
import SkeletonComponent from "./SkeletonComponent";

import useRequestArray from "hooks/useRequestArray";

function UserCard() {
    const { id } = useParams();
    const router = useHistory();
    const { responseData, isLoading, isError } = useRequestArray(`qr/userchecks/${id}`, "get");

    return (
        <Flex direction='column' pt={{ sm: "125px", md: "75px" }}>
            <Flex
                direction={{ sm: "column", md: "row" }}
                justify='space-between'
                align='center'
                w='100%'
                mb='24px'
            >
                <Text fontSize='4xl' color='grey' fontWeight='bold'>
                    Чеки пользователя
                </Text>
            </Flex>
            <Flex>
                {responseData.length !== 0 && !isLoading && <UserComponents data={responseData} />}
                {(responseData.length === 0 || isError) && !isLoading && (
                    <Flex align='center' gap='20px'>
                        <Button
                            w={{ sm: "100%", md: "max-content" }}
                            onClick={() => router.goBack()}
                        >
                            Назад
                        </Button>
                        <Text>Нет чеков</Text>
                    </Flex>
                )}
            </Flex>
            {isLoading && <SkeletonComponent height={100} />}
        </Flex>
    );
}

export default UserCard;
