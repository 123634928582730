import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { Flex, Button, Text } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CheckTable from "./components/CheckTable";
import MenuComponent from "./components/MenuComponent";
import PagePagination from "./components/PagePagination";
import StatBlock from "./components/StatBlock";
import InputComponent from "./components/InputComponent";
import CheckBoxComponent from "./components/CheckBoxComponent";
import DateRangePicker from "./components/DatePicker/DateRangePicker";
import SkeletonComponent from "./components/SkeletonComponent";

import useLocalStorage from "hooks/useLocalStorage";
import useSearchValue from "hooks/useSearchValue";
import useRequestArray from "hooks/useRequestArray";
import exportToExel from "helper/exportToExel";

function AllChecksData() {
    const router = useHistory();
    const qrLimitLs = useLocalStorage("qrLimit");
    const qrPage = useLocalStorage("qrPage");
    const [checkData, setCheckData] = useState({
        dateStart: localStorage.getItem("periodStart") || "",
        dateEnd: localStorage.getItem("periodEnd") || "",
    });
    const [status, setStatus] = useState(
        JSON.parse(localStorage.getItem("checkBoxFilter")) || {
            wait: true,
            verified: true,
            notVerified: true,
            onHold: true,
        }
    );
    const [searchValue, setSearchValue] = useState(localStorage.getItem("searchCheckValue") || "");
    const [order, setOrder] = useState(localStorage.getItem("qrOrders") || "DESC");
    const [limitOnPage, setLimitOnPage] = useState(qrLimitLs.getItem() || "5");
    const [page, setPage] = useState(qrPage.getItem() || 1);
    const allChecks = useRequestArray(
        `qr?order=${order}&limit=${
            searchValue.length ? "ALL" : limitOnPage
        }&page=${page}&period=${JSON.stringify(checkData)}&status=${JSON.stringify(status)}`,
        "get"
    );
    const filteredChecks = useSearchValue(allChecks.responseData.data, searchValue, page);

    function openCard(id) {
        router.push(`/dashboard/check-card/${id}`);
    }

    function handleChangeLimitOnPage(e) {
        const target = e.target.name;

        setLimitOnPage(target);
        setPage(1);

        qrLimitLs.setItem(target);
        qrPage.setItem(1);
    }

    useMemo(() => {
        localStorage.setItem("checkBoxFilter", JSON.stringify(status));
    }, [status]);

    return (
        <Flex direction='column' pt={{ sm: "125px", md: "75px" }}>
            <StatBlock />

            <InputComponent searchValue={searchValue} setSearchValue={setSearchValue} />

            <Flex mb='20px'>
                <Card>
                    <DateRangePicker
                        setCheckData={setCheckData}
                        checkData={checkData}
                        setPage={setPage}
                    />
                </Card>
            </Flex>

            <Flex
                direction={{ base: "column", md: "row" }}
                gap='20px'
                justify='space-between'
                align='center'
                w='100%'
                mb='24px'
            >
                <MenuComponent
                    limitOnPage={limitOnPage}
                    handleChangeLimitOnPage={handleChangeLimitOnPage}
                />

                <CheckBoxComponent
                    status={status}
                    setStatus={setStatus}
                    setPage={setPage}
                    activeFields={{ onHold: true, wait: true, verified: true, notVerified: true }}
                />

                <Button
                    w={{ base: "100%", md: "max-content" }}
                    onClick={() => exportToExel("Checks", filteredChecks)}
                >
                    Выгрузить чеки
                </Button>
            </Flex>

            <Card px='0px' py='0px'>
                <CardBody justifyContent='center'>
                    {allChecks.responseData.data && !allChecks.isError && !allChecks.isLoading && (
                        <CheckTable
                            data={filteredChecks}
                            open={openCard}
                            setOrder={setOrder}
                            order={order}
                            orderType={"qrOrders"}
                        />
                    )}
                    {allChecks.isError && <Text>Нет чеков по заданным параметрам</Text>}
                </CardBody>
                {allChecks.isLoading && <SkeletonComponent height={100} />}
            </Card>

            {limitOnPage !== "ALL" && searchValue.length === 0 && (
                <PagePagination
                    page={page}
                    setPage={setPage}
                    totalPages={allChecks.responseData.totalPages}
                    pageInLs='qrPage'
                    isError={allChecks.isError}
                />
            )}
        </Flex>
    );
}

export default AllChecksData;
