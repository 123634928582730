import { Flex, Td, Text, Table, Tbody, Th, Thead, Tr, IconButton } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import useRequest from "hooks/useRequest";
import EditCheckPattern from "./EditCheckPattern";

const CheckTable = ({ data, refetch }) => {
    const { deleteData } = useRequest("check-pattern");

    async function deleteCheckItem(id) {
        await deleteData(id);
        await refetch();
    }

    return (
        <Card>
            <CardBody>
                <Table variant='simple' size='sm' colorScheme='gray'>
                    <Thead flex='column' justifyContent='space-between'>
                        <Tr color='gray.500'>
                            <Th color='gray.500'>ID товара</Th>
                            <Th color='gray.500'>Наименование товара</Th>
                            <Th color='gray.500'>Тип товара</Th>
                            <Th color='gray.500'>Управление</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map((row) => {
                            return (
                                <Tr key={row.check_pattern_id}>
                                    <Td>
                                        <Flex align='center'>
                                            <Text w='max-content' color='gray.400' fontSize='md'>
                                                {row.check_pattern_id || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Text w='max-content' color='gray.400' fontSize='md'>
                                                {row.pattern_name || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Text w='max-content' color='gray.400' fontSize='md'>
                                                {row.item_type_name || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center' gap={5}>
                                            <EditCheckPattern
                                                id={row.check_pattern_id}
                                                currentValue={row.pattern_name}
                                                itemTypeName={row.item_type_name}
                                                refetch={refetch}
                                            />

                                            <IconButton
                                                size='sm'
                                                icon={<DeleteIcon boxSize={5} />}
                                                onClick={() =>
                                                    deleteCheckItem(row.check_pattern_id)
                                                }
                                            />
                                        </Flex>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

export default CheckTable;
