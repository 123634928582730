import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export default async function exportToExel(name, exportData) {
    const wb = { Sheets: { data: XLSX.utils.json_to_sheet(exportData) }, SheetNames: ["data"] };
    const data = new Blob([XLSX.write(wb, { bookType: "xlsx", type: "array" })], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    FileSaver.saveAs(data, name + ".xlsx");
}
