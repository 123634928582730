import { useParams } from "react-router-dom";
import { Flex, Text } from "@chakra-ui/react";

import CheckComponent from "./CheckComponent";
import PhotoCheckComponent from "./PhotoCheckComponent";

import useRequestObject from "hooks/useRequestObject";

function CheckCard() {
    const { id } = useParams();
    const { responseData, refetch } = useRequestObject(`qr/${id}`);

    return (
        <Flex direction='column' pt={{ sm: "125px", md: "75px" }}>
            <Flex
                direction={{ sm: "column", md: "row" }}
                justify='space-between'
                align='center'
                w='100%'
                mb='24px'
            >
                <Text fontSize='4xl' color='grey' fontWeight='bold'>
                    Чек
                </Text>
            </Flex>
            <Flex>
                {responseData.type === "photo" ? (
                    <PhotoCheckComponent data={responseData} />
                ) : (
                    <CheckComponent data={responseData} refetch={refetch} />
                )}
            </Flex>
        </Flex>
    );
}

export default CheckCard;
