import React from "react";
import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import WinnersTemplateTable from "./WinnersTemplateTable";

const WinnersTable = ({ data, openUserCard, openQrCard }) => {
    const prize = Object.entries(data);

    return (
        <Card overflowX='scroll'>
            <CardBody>
                <Table variant='striped' size='sm'>
                    <Thead flex='column' justifyContent='space-between'>
                        <Tr color='gray.500'>
                            <Th color='gray.500'>Id пользователя</Th>
                            <Th color='gray.500'>Email пользователя</Th>
                            <Th color='gray.500'>ФИО</Th>
                            <Th color='gray.500'>Номер телефона</Th>
                            <Th color='gray.500'>Чек</Th>
                            <Th color='gray.500'>Приз</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {prize.map((el, i) => (
                            <WinnersTemplateTable
                                key={i}
                                data={el[1]}
                                openQrCard={openQrCard}
                                openUserCard={openUserCard}
                                prize={el[0]}
                            />
                        ))}
                    </Tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

export default WinnersTable;
