import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { format } from "date-fns";
import { useColorMode, Text } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const DateRangePicker = ({ setCheckData, checkData, setPage }) => {
    const { colorMode } = useColorMode();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleStartDateChange = (date) => {
        const formattedDate = format(date, "yyyy-MM-dd");
        setCheckData({ ...checkData, dateStart: formattedDate });
        setStartDate(date);

        localStorage.setItem("periodStart", formattedDate);
    };

    const handleEndDateChange = (date) => {
        const formattedDate = format(date, "yyyy-MM-dd");
        setCheckData({ ...checkData, dateEnd: formattedDate });
        setEndDate(date);

        localStorage.setItem("periodEnd", formattedDate);
    };

    function handleClearBtn() {
        setCheckData({ dateStart: "", dateEnd: "" });
        setStartDate("");
        setEndDate("");
        setPage(1);
        localStorage.removeItem("periodStart");
        localStorage.removeItem("periodEnd");
    }

    return (
        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText='Дата начала'
                dateFormat='dd/MM/yyyy'
                locale={ru}
                className={
                    colorMode === "dark" ? "custom-datepicker-dark" : "custom-datepicker-light"
                }
            />

            <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                placeholderText='Дата окончания'
                dateFormat='dd/MM/yyyy'
                locale={ru}
                className={
                    colorMode === "dark" ? "custom-datepicker-dark" : "custom-datepicker-light"
                }
                minDate={startDate}
            />

            <CloseIcon role='button' w='20px' h='20px' color='red.500' onClick={handleClearBtn} />

            {localStorage.getItem("periodStart") && (
                <Text>
                    Выбранный период:
                    {` ${localStorage.getItem("periodStart") || ""} -
                ${localStorage.getItem("periodEnd") || ""}`}
                </Text>
            )}
        </div>
    );
};

export default DateRangePicker;
