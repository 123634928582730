import { Flex, Text } from "@chakra-ui/react";

import Card from "components/Card/Card";

import CheckPatternTable from "./components/CheckPatternTable";
import AddCheckItemPopover from "./components/AddCheckItemPopover";

import useRequestArray from "hooks/useRequestArray";

export default function CheckPattern() {
    const checkPattern = useRequestArray("item-type", "get");

    return (
        <Flex direction='column' pt={{ sm: "125px", md: "75px" }} gap='20px'>
            <AddCheckItemPopover refetch={checkPattern.refetch} />

            {checkPattern.responseData && !checkPattern.isError && (
                <CheckPatternTable
                    data={checkPattern.responseData}
                    refetch={checkPattern.refetch}
                />
            )}

            {(checkPattern.isError || !checkPattern.responseData) && (
                <Card>
                    <Text textAlign='center'>Нет товаров</Text>
                </Card>
            )}
        </Flex>
    );
}
