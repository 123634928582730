import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import axios from "axios";

export default function useRequest(path) {
    const toast = useToast();

    const [getRequestData, setGetRequest] = useState({
        responseData: [],
        isLoading: false,
        isError: false,
        isFetching: true,
        error: "",
    });

    const [postRequestData, setPostRequest] = useState({
        responseData: [],
        isLoading: false,
        isError: false,
        isFetching: true,
        error: "",
    });

    const [deleteRequestData, setDeleteRequest] = useState({
        responseData: [],
        isLoading: false,
        isError: false,
        isFetching: true,
        error: "",
    });

    const [putRequestData, setPutRequest] = useState({
        responseData: [],
        isLoading: false,
        isError: false,
        isFetching: true,
        error: "",
    });

    async function getData(toastData) {
        try {
            setGetRequest({ ...getRequestData, isLoading: true, isError: false, isFetching: true });

            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/${path}`);

            setGetRequest({
                ...getRequestData,
                isLoading: false,
                isError: false,
                isFetching: false,
                responseData: data.data,
            });

            toast({
                title: toastData?.successTitle || "Успешно",
                status: "success",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
        } catch (error) {
            setGetRequest({
                ...getRequestData,
                isLoading: false,
                isError: true,
                isFetching: false,
                error: error.message,
            });
            toast({
                title: toastData?.errorTitle || "Ошибка",
                status: "error",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
        }
    }

    async function postData(body, toastData, onClose) {
        try {
            setPostRequest({
                ...postRequestData,
                isLoading: true,
                isError: false,
                isFetching: true,
            });

            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/${path}`, body);

            setPostRequest({
                ...postRequestData,
                isLoading: false,
                isError: false,
                isFetching: false,
                responseData: data.data,
            });

            if (onClose) onClose();

            toast({
                title: toastData?.successTitle || "Успешно",
                status: "success",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
        } catch (error) {
            setPostRequest({
                ...postRequestData,
                isLoading: false,
                isError: true,
                isFetching: false,
                error: error.message,
            });

            if (onClose) onClose();

            toast({
                title: toastData?.errorTitle || "Ошибка",
                status: "error",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
        }
    }

    async function deleteData(params, toastData) {
        try {
            setDeleteRequest({
                ...deleteRequestData,
                isLoading: true,
                isError: false,
                isFetching: true,
            });

            const { data } = await axios.delete(
                `${process.env.REACT_APP_API_URL}/${path}/${params}`
            );

            setDeleteRequest({
                ...deleteRequestData,
                isLoading: false,
                isError: false,
                isFetching: false,
                responseData: data.data,
            });

            toast({
                title: toastData?.successTitle || "Успешно",
                status: "success",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
        } catch (error) {
            setDeleteRequest({
                ...deleteRequestData,
                isLoading: false,
                isError: true,
                isFetching: false,
                error: error.message,
            });
            toast({
                title: toastData?.errorTitle || "Ошибка",
                status: "error",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
        }
    }

    async function putData(body, toastData) {
        try {
            setPutRequest({
                ...putRequestData,
                isLoading: true,
                isError: false,
                isFetching: true,
            });

            const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/${path}`, body);

            setPutRequest({
                ...putRequestData,
                isLoading: false,
                isError: false,
                isFetching: false,
                responseData: data.data,
            });

            toast({
                title: toastData?.successTitle || "Успешно",
                status: "success",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
        } catch (error) {
            setPutRequest({
                ...putRequestData,
                isLoading: false,
                isError: true,
                isFetching: false,
                error: error.message,
            });
            toast({
                title: toastData?.errorTitle || "Ошибка",
                status: "error",
                isClosable: true,
                duration: 3000,
                position: "top",
            });
        }
    }

    return {
        getRequestData,
        getData,
        postRequestData,
        postData,
        deleteRequestData,
        deleteData,
        putRequestData,
        putData,
    };
}
