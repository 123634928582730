import { Flex, Icon, Td, Text, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { BsCircleFill } from "react-icons/bs";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import { dateConvert } from "helper/dateConvert";

const CheckTable = ({ data, open, setOrder, order, orderType }) => {
    function changeDateSortOrder() {
        setOrder((prev) => {
            localStorage.setItem(orderType, prev === "ASC" ? "DESC" : "ASC");

            return prev === "ASC" ? "DESC" : "ASC";
        });
    }

    return (
        <Card overflowX='scroll'>
            <CardBody>
                <Table variant='striped' size='sm' colorScheme='gray'>
                    <Thead flex='column' justifyContent='space-between'>
                        <Tr color='gray.500'>
                            <Th color='gray.500'>id чека</Th>
                            <Th role='button' color='gray.500'>
                                <Flex
                                    justify='space-between'
                                    align='center'
                                    gap='10px'
                                    onClick={changeDateSortOrder}
                                >
                                    <Text>Дата и время загрузки</Text>
                                    {order === "DESC" && <TriangleDownIcon w='15px' h='15px' />}
                                    {order === "ASC" && <TriangleUpIcon w='15px' h='15px' />}
                                </Flex>
                            </Th>
                            <Th color='gray.500'>Email загрузившего</Th>
                            <Th color='gray.500'>ФИО</Th>
                            <Th color='gray.500'>Номер телефона</Th>
                            <Th color='gray.500'>Сумма чека</Th>
                            <Th color='gray.500'>Статус валидации</Th>
                            <Th color='gray.500'>Статус пользователя</Th>
                            <Th color='gray.500'>Место продажи</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.map((row) => {
                            return (
                                <Tr key={row.id}>
                                    <Td>
                                        <Flex align='center'>
                                            <Text
                                                w='max-content'
                                                role='button'
                                                onClick={() => open(row.id)}
                                                fontSize='md'
                                                fontWeight='bold'
                                            >
                                                {row.id || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Text w='max-content' fontSize='md' fontWeight='bold'>
                                                {dateConvert(row.create_date) || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Text w='max-content' color='gray.400' fontSize='md'>
                                                {row.email || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Text w='max-content' color='gray.400' fontSize='md'>
                                                {row.fio || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Text w='max-content' color='gray.400' fontSize='md'>
                                                {row.phone || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Text w='max-content' fontSize='md' fontWeight='bold'>
                                                {row.summary || "-"}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex w='max-content' align='center'>
                                            <Icon
                                                as={BsCircleFill}
                                                w='8px'
                                                h='8px'
                                                color={
                                                    row.qrcode_status === "verified"
                                                        ? "teal.300"
                                                        : row.qrcode_status === "notVerified"
                                                        ? "red.500"
                                                        : "grey.400"
                                                }
                                                me='6px'
                                            />
                                            <Text w='max-content' color='gray.400' fontSize='md'>
                                                {row.qrcode_status === "verified" ? "Принят" : ""}
                                                {row.qrcode_status === "notVerified"
                                                    ? "Отклонен"
                                                    : ""}
                                                {row.qrcode_status === "wait" ? "На модерации" : ""}
                                                {row.qrcode_status === "onHold"
                                                    ? "На ожидании"
                                                    : ""}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Flex align='center'>
                                            <Icon
                                                as={BsCircleFill}
                                                w='8px'
                                                h='8px'
                                                color={
                                                    row.account_status === "active"
                                                        ? "teal.300"
                                                        : row.account_status === "not active"
                                                        ? "red.500"
                                                        : "grey.400"
                                                }
                                                me='6px'
                                            />
                                            <Text w='max-content' color='gray.400' fontSize='md'>
                                                {row.account_status === "active"
                                                    ? "Подтвержденный пользователь"
                                                    : ""}
                                                {row.account_status === "not active"
                                                    ? "Неподтвержденный пользователь"
                                                    : ""}
                                            </Text>
                                        </Flex>
                                    </Td>

                                    <Td>
                                        <Text w='max-content' color='gray.400' fontSize='md'>
                                            {row.retail_place || "Нет данных"}
                                        </Text>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

export default CheckTable;
