import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Link,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";

import basic from "assets/img/basic-auth.png";

import AuthService from "../../../services/AuthService";
import { AuthContext } from "contexts/AuthContext";

function SignUp() {
    const titleColor = useColorModeValue("teal.300", "teal.200");
    const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("white", "gray.700");
    const textError = useColorModeValue("rgba(178, 34, 34, 1)");
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [isloading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const history = useHistory();
    const { setIsAuth } = useContext(AuthContext);

    async function onLogin() {
        try {
            setIsLoading(true);
            await AuthService.login(login, password).then((res) => {
                if (res.status === 200) {
                    localStorage.setItem("token", res.data.accessToken);
                    localStorage.setItem("auth", true);
                    setIsAuth(true);
                    setIsLoading(false);

                    history.push("/dashboard/all-checks");
                }
            });
            setIsLoading(false);
        } catch (e) {
            setErrorMessage("неверный логин или пароль");
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setErrorMessage("");
    }, [password, login]);

    return (
        <Flex direction='column' alignSelf='center' justifySelf='center' overflow='hidden'>
            <Box
                position='absolute'
                minH={{ base: "70vh", md: "50vh" }}
                w={{ md: "calc(100vw - 50px)" }}
                borderRadius={{ md: "15px" }}
                left='0'
                right='0'
                bgRepeat='no-repeat'
                overflow='hidden'
                zIndex='-1'
                top='0'
                bgImage={basic}
                bgSize='cover'
                mx={{ md: "auto" }}
                mt={{ md: "14px" }}
            ></Box>
            <Flex
                direction='column'
                textAlign='center'
                justifyContent='center'
                align='center'
                mt='6.5rem'
                mb='30px'
            >
                <Text fontSize='2xl' color='white' fontWeight='bold'>
                    Добро пожаловать!
                </Text>
                <Text
                    fontSize='md'
                    color='white'
                    fontWeight='normal'
                    mt='10px'
                    mb='26px'
                    w={{ base: "90%", sm: "60%", lg: "40%", xl: "20%" }}
                >
                    Пожалуйста, авторизуйтесь для доступа к системе
                </Text>
            </Flex>
            <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
                <Flex
                    direction='column'
                    w='445px'
                    background='transparent'
                    borderRadius='15px'
                    p='40px'
                    mx={{ base: "100px" }}
                    bg={bgColor}
                    boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'
                >
                    <Text
                        fontSize='xl'
                        color={textColor}
                        fontWeight='bold'
                        textAlign='center'
                        mb='22px'
                    >
                        Авторизация
                    </Text>
                    <FormControl>
                        <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                            Логин
                        </FormLabel>
                        <Input
                            value={login}
                            onChange={(event) => setLogin(event.target.value)}
                            fontSize='sm'
                            ms='4px'
                            borderRadius='15px'
                            type='text'
                            placeholder='Введите ваш логин'
                            mb='24px'
                            size='lg'
                        />
                        <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                            Пароль
                        </FormLabel>
                        <Input
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            fontSize='sm'
                            ms='4px'
                            borderRadius='15px'
                            type='password'
                            placeholder='Введите ваш пароль'
                            mb='24px'
                            size='lg'
                        />
                        <Button
                            loadingText='Вход'
                            isLoading={isloading}
                            onClick={onLogin}
                            type='submit'
                            bg='teal.300'
                            fontSize='sm'
                            color='white'
                            fontWeight='bold'
                            w='100%'
                            h='45'
                            mb='24px'
                            _hover={{
                                bg: "teal.200",
                            }}
                            _active={{
                                bg: "teal.400",
                            }}
                        >
                            Вход
                        </Button>
                        <Text
                            hidden={!(errorMessage !== "")}
                            fontSize='sm'
                            color={textError}
                            textAlign='center'
                            mb='0px'
                        >
                            {errorMessage}
                        </Text>
                    </FormControl>
                    <Flex
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                        maxW='100%'
                        mt='0px'
                    >
                        {/* <Text color={textColor} fontWeight='medium'>
                            Нет учетной записи?
                            <Link
                                color={titleColor}
                                as='span'
                                ms='5px'
                                href='#'
                                fontWeight='bold'
                                onClick={() => history.push("/auth/reg")}
                            >
                                Зарегистрироваться
                            </Link>
                        </Text> */}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default SignUp;
