import { Stack, Checkbox } from "@chakra-ui/react";

export default function CheckBoxComponent({ status, setStatus, setPage, activeFields }) {
    const { onHold, wait, verified, notVerified } = activeFields;

    function getCheckBoxStatus(e) {
        setStatus({ ...status, [e.target.name]: e.target.checked });
        setPage(1);
    }

    return (
        <Stack spacing={4} direction='row' onChange={getCheckBoxStatus}>
            {onHold && (
                <Checkbox isChecked={status.onHold} name='onHold' value={status.onHold}>
                    На ожидании
                </Checkbox>
            )}
            {wait && (
                <Checkbox isChecked={status.wait} name='wait' value={status.wait}>
                    На модерации
                </Checkbox>
            )}
            {verified && (
                <Checkbox isChecked={status.verified} name='verified' value={status.verified}>
                    Принят
                </Checkbox>
            )}
            {notVerified && (
                <Checkbox
                    isChecked={status.notVerified}
                    name='notVerified'
                    value={status.notVerified}
                >
                    Отклонен
                </Checkbox>
            )}
        </Stack>
    );
}
