import { useState } from "react";
import { Text, Flex, IconButton } from "@chakra-ui/react";
import { AddIcon, CheckIcon } from "@chakra-ui/icons";

import ItemTypeMenuComponent from "./ItemTypeMenuComponent";

import useRequest from "hooks/useRequest";
import useRequestArray from "hooks/useRequestArray";

export default function FnsCheckList({ fnsResponse, refetchData }) {
    const { responseData, refetch } = useRequestArray("check-pattern", "get");

    const { postData } = useRequest("check-pattern");
    const [selectedItemTypes, setSelectedItemTypes] = useState({});

    async function addCheckPattern(patternName, itemTypeNameId) {
        let errorMessage = "Чек уже добавлен в список автомодерации или ошибка сервера";
        if (!itemTypeNameId) errorMessage = "Выберите тип товара";

        await postData({ patternName, itemTypeNameId }, { errorTitle: errorMessage });

        await refetch();
        await refetchData();
    }

    const handleSelectItemType = (index, id) => {
        setSelectedItemTypes((prev) => ({
            ...prev,
            [index]: id,
        }));
    };

    console.log("selectedItemTypes", selectedItemTypes);

    function checkItem(item) {
        return responseData?.some(({ pattern_name }) => pattern_name === item && pattern_name);
    }

    return (
        <>
            <Text fontWeight='bold'>Ответ от ФНС:</Text>
            {fnsResponse?.map((el, index) => (
                <Flex align='center' gap={5} key={index}>
                    {!checkItem(el) &&
                        el !== "Не найден акционный товара в чеке" &&
                        el !== "Формат отправленных данных некорректен" &&
                        el !== "Внутренняя ошибка сервиса получения чеков" &&
                        el !== "Ошибка hsm. Чек не валиден или любая внутренняя ошибка hsm" &&
                        el !== "Не прошла проверка пара ККТ+ФН" &&
                        el !== "Не найдены данные в сервисе поиска чека" && (
                            <>
                                <IconButton
                                    size='sm'
                                    icon={<AddIcon boxSize={5} />}
                                    onClick={() => addCheckPattern(el, selectedItemTypes[index])}
                                />
                                <ItemTypeMenuComponent
                                    handleSelectItemType={handleSelectItemType}
                                    index={index}
                                />
                            </>
                        )}

                    {checkItem(el) && <CheckIcon boxSize={5} color='green' />}
                    <Text>{el}</Text>
                </Flex>
            ))}
        </>
    );
}
