import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
    Flex,
    Button,
    SimpleGrid,
    Heading,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import WinnersTable from "./components/WinnersTable";
import ConfirmModal from "./components/ConfirmModal";
import SkeletonComponent from "./components/SkeletonComponent";

import exportToExel from "helper/exportToExel";
import useRequestArray from "hooks/useRequestArray";

function WinnersBlock() {
    const router = useHistory();
    const [week, setWeek] = useState(localStorage.getItem("winnerWeek") || "Выберите период");
    const rafflePeriod = useRequestArray("raffle/get-winner-period", "get");
    const weeklyWinners = useRequestArray(
        `raffle/get-weekly-winners?raffleWeek=${week}&hide=false`,
        "post"
    );

    function openUserCard(id) {
        router.push(`/dashboard/user-card/${id}`);
    }
    function openQrCard(id) {
        router.push(`/dashboard/check-card/${id}`);
    }

    function getPeriodWinners(e) {
        const target = e.target.textContent;
        localStorage.setItem("winnerWeek", target);
        setWeek(target);
    }

    return (
        <>
            <Flex
                direction='column'
                align={{ sm: "center", md: "flex-start" }}
                pt={{ sm: "125px", md: "75px" }}
            >
                <SimpleGrid w='100%' columns={{ sm: "1" }} gap='20px' mb='24px'>
                    <Card>
                        <CardHeader justifyContent='center'>
                            <Heading>Выбор периода</Heading>
                        </CardHeader>

                        <Flex gap='20px' mt='20px' direction={{ sm: "column", md: "row" }}>
                            <CardBody>
                                <Menu>
                                    <MenuButton
                                        w={{ sm: "100%", md: "max-content" }}
                                        as={Button}
                                        rightIcon={<ChevronDownIcon />}
                                    >
                                        {week}
                                    </MenuButton>

                                    <MenuList onClick={getPeriodWinners}>
                                        <MenuItem>За все время</MenuItem>
                                        {rafflePeriod.responseData?.map((el, index) => (
                                            <MenuItem key={index}>{el}</MenuItem>
                                        ))}
                                    </MenuList>
                                </Menu>
                            </CardBody>

                            <ConfirmModal week={week} refetch={weeklyWinners.refetch} />
                        </Flex>
                    </Card>
                </SimpleGrid>

                <Button
                    w={{ sm: "100%", md: "max-content" }}
                    fontSize={{ sm: "13px", md: "16px" }}
                    mb='20px'
                    onClick={() => exportToExel("Winners", weeklyWinners.responseData)}
                >
                    Выгрузить победителей за выбранный период
                </Button>
                {weeklyWinners.responseData &&
                    !weeklyWinners.isError &&
                    !weeklyWinners.isLoading && (
                        <WinnersTable
                            data={weeklyWinners.responseData}
                            openUserCard={openUserCard}
                            openQrCard={openQrCard}
                            refetch={weeklyWinners.refetch}
                        />
                    )}
                {weeklyWinners.isError && <Text>Нет победителей</Text>}
            </Flex>

            {weeklyWinners.isLoading && <SkeletonComponent height={100} />}
        </>
    );
}

export default WinnersBlock;
