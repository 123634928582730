import { Flex, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

export default function InputComponent({ searchValue, setSearchValue }) {
    function inputValue(e) {
        setSearchValue(e.target.value);
        localStorage.setItem("searchUserValue", e.target.value);
    }

    return (
        <Flex mb='25px'>
            <InputGroup>
                <InputLeftElement pointerEvents='none' pl='10px' pt='5px'>
                    <SearchIcon color='gray.300' />
                </InputLeftElement>

                <Input
                    value={searchValue}
                    onChange={inputValue}
                    w='100%'
                    fontSize='sm'
                    ms='4px'
                    borderRadius='15px'
                    type='text'
                    placeholder='Поиск...'
                    mb='4px'
                    size='lg'
                />
            </InputGroup>
        </Flex>
    );
}
