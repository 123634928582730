import { useState } from "react";

import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    Button,
    Input,
    Flex,
    IconButton,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";

import ItemTypeMenuComponent from "./ItemTypeMenuComponent";

import useRequest from "hooks/useRequest";

export default function EditCheckPattern({ id, currentValue, refetch }) {
    const { putData } = useRequest("check-pattern");

    const [value, setValue] = useState({
        patternName: currentValue,
        itemType: "",
        itemTypeNameId: "",
    });

    async function updateCheckPattern(id) {
        await putData({ ...value, id });
        await refetch();
    }

    function getInputValue(e) {
        setValue({ ...value, [e.target.name]: e.target.value });
    }

    const handleSelectItemType = (type, id) => {
        setValue((prev) => ({
            ...prev,
            itemType: type,
            itemTypeNameId: id,
        }));
    };

    return (
        <Popover placement='right'>
            <PopoverTrigger>
                <IconButton size='sm' icon={<EditIcon boxSize={5} />} />
            </PopoverTrigger>
            <PopoverContent p={5}>
                <Flex direction='column' gap={5}>
                    <Input
                        name='patternName'
                        placeholder='Введите новое наименование'
                        onChange={getInputValue}
                        value={value.patternName}
                    />

                    <ItemTypeMenuComponent handleSelectItemType={handleSelectItemType} />

                    <Button colorScheme='green' onClick={() => updateCheckPattern(id)}>
                        Сохранить
                    </Button>
                </Flex>
            </PopoverContent>
        </Popover>
    );
}
